import React, { useEffect, useState } from 'react';
import { FacebookProvider } from 'react-facebook';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { Navigate, Route, Routes } from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';
import airbridge from 'airbridge-web-sdk-loader';
import { AxiosResponse } from 'axios';
import { PostHogProvider } from 'posthog-js/react';
import { SWRConfig } from 'swr';
import TiktokPixel from 'tiktok-pixel';

import ProtectedRoute from '@/components/utility/auth/ProtectedRoute';
import ErrorBoundary from '@/components/utility/ErrorBoundary';
import SnackbarComponent from '@/components/utility/microcomponents/Snackbar';
import AccountProvider from '@/contexts/AccountContext';
import SnackbarProvider from '@/contexts/SnackBarContext';
import { AccountModel } from '@/models/Account';
import { initApi } from '@/utility/api';

import CookiePopup from './components/utility/microcomponents/CookiePopup';
import ActivityPage from './pages/ActivityPage';
import CampaignMetaPage from './pages/CampaignMetaPage';
import CampaignPlaylistPitchPage from './pages/CampaignPlaylistPitchPage';
import CampaignsPage from './pages/CampaignsPage';
import CampaignTikTokPage from './pages/CampaignTikTokPage';
import HomePage from './pages/HomePage';
import InsightsPage from './pages/InsightsPage';
import LoginPage from './pages/LoginPage';
import MetaCallbackPage from './pages/MetaCallbackPage';
import OnboardingPage from './pages/OnboardingPage';
import PaymentConfirmationPage from './pages/PaymentConfirmationPage';
import ProfilePage from './pages/ProfilePage';
import PromotePage from './pages/PromotePage';
import SettingsPage from './pages/SettingsPage';
import TikTokCallbackPage from './pages/TikTokCallbackPage';
import ToDoPage from './pages/ToDoPage';
import VerifyEmailPage from './pages/VerifyEmailPage';

import './App.scss';

declare global {
  interface Window {
    popupCallback: Promise<AxiosResponse<AccountModel> | null | undefined>;
    Intercom: (
      value: string,
      data?:
        | {
            app_id: string;
            name?: string;
            email?: string;
            user_id?: string;
            user_hash?: string;
          }
        | (() => void)
    ) => void;
    intercomSettings: {
      app_id: string;
      alignment: string;
      horizontal_padding: number;
      vertical_padding: number;
      custom_launcher_selector: string;
    };
  }
}

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);

ReactPixel.init(import.meta.env.VITE_REACT_PIXEL);
ReactPixel.pageView();
TiktokPixel.init(import.meta.env.VITE_TIKTOK_PIXEL);
TiktokPixel.pageView();

ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS);
hotjar.initialize(import.meta.env.VITE_HOTJAR_ID, 6);

airbridge.init({
  app: import.meta.env.VITE_AIRBRIDGE_APP_NAME || '',
  webToken: import.meta.env.VITE_AIRBRIDGE_WEB_SDK_TOKEN || '',
  utmParsing: true,
});

initApi(import.meta.env.VITE_API_URL);

const App = () => {
  const [cookiePolicyOpen, setCookiePolicyOpen] = useState(false);

  useEffect(() => {
    setCookiePolicyOpen(!localStorage.getItem('cookieChoice'));
  }, []);

  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          shouldRetryOnError: false,
        }}
      >
        <SnackbarProvider>
          <SnackbarComponent />
          {cookiePolicyOpen && <CookiePopup onClose={() => setCookiePolicyOpen(false)} />}
          <AccountProvider>
            <FacebookProvider appId={import.meta.env.VITE_FACEBOOK_APP_ID}>
              <div className="App">
                <PostHogProvider
                  apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
                  options={{
                    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
                  }}
                >
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/sign-up" element={<LoginPage isSignUp={true} />} />
                    <Route element={<ProtectedRoute />}>
                      <Route path="/" element={<Navigate to="/home" />} />
                      <Route path="/home" element={<HomePage />} />
                      <Route path="/onboarding" element={<OnboardingPage />} />
                      <Route path="/to-do-list" element={<ToDoPage />} />
                      <Route path="/campaigns" element={<CampaignsPage />} />
                      <Route path="/campaigns/playlist-pitch/:slug" element={<CampaignPlaylistPitchPage />} />
                      <Route path="/campaigns/tiktok/:slug" element={<CampaignTikTokPage />} />
                      <Route path="/campaigns/meta/:slug" element={<CampaignMetaPage />} />
                      <Route path="/insights" element={<InsightsPage />} />
                      <Route path="/promote" element={<PromotePage />} />
                      <Route path="/settings" element={<SettingsPage />} />
                      <Route path="/profile" element={<ProfilePage />} />
                      <Route path="/activity" element={<ActivityPage />} />
                      <Route path="/email-verification" element={<VerifyEmailPage />} />
                      <Route path="/tiktok-callback" element={<TikTokCallbackPage />} />
                      <Route path="/meta-callback" element={<MetaCallbackPage />} />
                      <Route
                        path="/payment-confirmation/meta"
                        element={<PaymentConfirmationPage productType={'meta'} />}
                      />
                      <Route
                        path="/payment-confirmation/tiktok"
                        element={<PaymentConfirmationPage productType={'tiktok'} />}
                      />
                      <Route
                        path="/payment-confirmation/playlist"
                        element={<PaymentConfirmationPage productType={'playlist'} />}
                      />
                      <Route
                        path="/payment-confirmation/subscription"
                        element={<PaymentConfirmationPage productType={'subscription'} />}
                      />
                      <Route path="*" element={<Navigate to="/home" />} />
                    </Route>
                  </Routes>
                </PostHogProvider>
              </div>
            </FacebookProvider>
          </AccountProvider>
        </SnackbarProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
};

export default App;
